import React, { FC } from "react";
import { Helmet } from 'react-helmet';
import { ButtonVariantEnum } from "../utils/enums/button";
import { navigate } from "gatsby";
import Button from "../components/button/Button";
import Layout from "../layout/layout/Layout";

const NotFoundPage: FC = () => {
    return (
        <>
            <Helmet title="Get Real - Got Milk. | 404" />
            <Layout pageClassName="not-found-page">
                <div className="not-found-page__wrapper">
                    <div className="not-found-page__content">
                        <h1 className="not-found-page__heading">Lost your way?</h1>
                        <p className="not-found-page__subheading">Sorry, we can't find that page. You'll find loads to explore on the home page</p>
                        <Button
                            className="not-found-page__btn"
                            label='RETURN HOME'
                            type={ButtonVariantEnum.PRIMARY}
                            onClick={() => navigate('/')}
                        />
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default NotFoundPage;